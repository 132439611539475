import request from '@/utils/request';

export function projects(params) {
  return request({
    url: '/api/apply/projects',
    method: 'get',
    headers: {
      'Cache-control': 'no-cache',
      Pragma: 'no-cache',
    },
    params,
  });
}

export function sectionList(params) {
  return request({
    url: '/api/apply/sections',
    method: 'get',
    params,
  });
}

export function applyDetail(params) {
  return request({
    url: '/api/apply/applyDetail',
    method: 'get',
    params,
  });
}
export function apply(params) {
  return request({
    url: '/api/apply/apply',
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    params,
  });
}
export function planList() {
  return request({
    url: '/api/apply/planList',
    method: 'get',
  });
}

export function insureApply(data) {
  return request({
    url: '/api/apply/insureApply',
    method: 'POST',
    data,
  });
}


export default { insureApply, projects, sectionList, applyDetail, apply, planList };
