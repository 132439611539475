<template>
  <div>
    <a-spin :spinning="loading">
      <div class="project">
        <div class="main">
          <Description
            class="description"
            title="投保人信息"
            :data-source="insureData"
          />
          <Description
            class="description"
            title="项目信息"
            :data-source="orderData"
          />
          <Description
            class="description"
            title="招标人信息"
            :data-source="projectData"
          />
          <Description
            class="description"
            title="机构"
          />
          <div class="palnList">
            <div
              v-for="item in planList"
              :key="item.id"
              @click="palnListChange(item)"
              :class="{ palnListBox: palnListBox,palnListBoxAct: item.isPalnList}"
            >
              <div
                v-if="item.id === 2 && $store.state.discount.enableFlag === '1'"
                class="feilv"
              >
                费率千三
              </div>
              <img
                v-if="item.isPalnList"
                :src="item.imgAct"
              >
              <img
                v-else
                :src="item.img"
              >
              <div class="palnText">
                <span>{{ item.planName }}</span>
                <p>{{ item.support }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="btn">
          <a-button @click="back">
            返回上一步
          </a-button>
          <a-button
            type="primary"
            @click="submit"
          >
            确定并进入下一步
          </a-button>
        </div>
      </div>
    </a-spin>
    <a-modal
      v-model="visible"
      :cancel-button-props="{ style: { display: 'none' } }"
      @ok="handleOk"
      centered
      :mask-closable="false"
      :keyboard="false"
      ok-text="确定"
    >
      <p>用户未登录，请重新登录。</p>
    </a-modal>
    <div
      class="alerter"
      ref="alerter"
    >
      <a-modal
        v-model="visibleAlert"
        :cancel-button-props="{ style: { display: 'none' } }"
        :ok-button-props="{ style: { display: 'none' } }"
        @ok="handleOk"
        centered
        :mask-closable="false"
        :keyboard="false"
        ok-text="确定"
        :get-container="()=>$refs.alerter"
      >
        <p>即将进入天彩保险经纪有限公司自营平台投保界面</p>
        <a-spin style="margin: auto;" />
        <!-- <p>倒计时{{ time }}秒</p> -->
      </a-modal>
    </div>
  </div>
</template>

<script>
import { dateFormat, dateFormatDay } from '@/utils/format.js';
import Description from '@/components/Description';
import { applyDetail, apply, planList } from '@/api/insure/project.js';
import bankImg from '@/assets/image/icon-yhjg-hor.png';
import bankImgAct from '@/assets/image/icon-yhjg-hover.png';
import tbgsImg from '@/assets/image/icon-tbgs-hor.png';
import tbgsImgAct from '@/assets/image/icon-tbgs-hover.png';
export default {
  name: 'ConInsure',
  components: {
    Description,
  },
  data() {
    return {
      time: 2,
      once: true,
      palnListBox: true,
      isPalnList: true,
      planValue: 0,
      planList: {},
      visible: false,
      visibleAlert: false,
      loading: false,
      sectionId: '',
      insureData: {
        companyName: '',
        creditCode: '',
      },
      orderData: {
        projectName: '',
        sectionName: '',
        sectionCode: '',
        limit: '',
        bidOpenTime: '',
        payOpenTime: '',
      },
      projectData: {
        tenderName: '',
        tenderCode: '',
        tenderAddress: '',
        tenderContactPhone: '',
      },
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    submit() {
      console.log(this.planValue);
      if (this.planValue === 2) {
        if (this.once) {
          this.visibleAlert = true;
          this.time = 2;
          this.once = false;
          const timer = setInterval(() => {
            this.time -= 1;
            console.log(this.time);
            if (this.time === -1) {
              this.time = 0;
              clearInterval(timer);
              this.apply();
              this.visibleAlert = false;
            }
          }, 1000);
        }
      } else {
        this.apply();
      }
    },
    handleOk() {
    },
    palnListChange(e) {
      this.planValue = e.id;
      this.planList.map(item => {
        if (item.id === e.id) {
          item.isPalnList = true;
        } else {
          item.isPalnList = false;
        }
        return false;
      });
    },
    apply() {
      this.once = true;
      const planNo = this.planList.filter(item => {
        if (item.id === this.planValue) {
          return item;
        }
        return false;
      });
      if (this.planValue === 88) {
        this.$router.push({ name: 'bankInsure' });
      } else {
        apply({ sectionId: this.sectionId, planNo: planNo[0].planNo }).then(res => {
          if (res.data.status === 'FAIL') {
            if (res.data.errorCode === '1001') {
              const that = this;
              console.log(this);
              this.$confirm({
                title: res.data.errorMsg,
                // content: res.data.errorMsg,
                okText: '确认',
                cancelText: '取消',
                onOk() {
                  console.log(this);
                  that.$router.push({ name: 'entCertForm' });
                },
                onCancel() {},
              });
            } else {
              this.$message.warning(res.data.errorMsg);
            }
          } else {
            if (this.planValue === 6) {
              window.open(`${process.env.VUE_APP_DANBAO_HREF}/#/tenderNotice?orderNo=${res.data.data}`);
            } else {
              window.open(`${process.env.VUE_APP_INSURE_HREF}/#/tenderNotice?orderNo=${res.data.data}`);
            }
          }
        });
      }
    },
    back() {
      // this.$parent.changeActive(0);
      this.$router.go(-1);
    },
    fetch() {
      planList().then(res => {
        res.data.data.map(item => {
          if (item.id === 2) {
            item.img = tbgsImg;
            item.imgAct = tbgsImgAct;
            item.support = '支持：长安保险、平安保险、天安保险';
            item.isPalnList = false;
          } else if (item.id === 6) {
            item.img = tbgsImg;
            item.imgAct = tbgsImgAct;
            item.support = '支持：瀚华担保';
            item.isPalnList = false;
          }
          return false;
        });
        this.planList = res.data.data;
        const bank = {
          createDate: 1630396598000,
          id: 88,
          planName: '银行机构',
          support: '支持：上海银行',
          planNo: 'BANK_01',
          planType: '2',
          status: '1',
          label: 'bank',
          img: bankImg,
          imgAct: bankImgAct,
          isPalnList: false,
        };
        this.planList.push({ ...bank });
      });
      this.loading = true;
      this.sectionId = JSON.parse(sessionStorage.getItem('projectSectionId'));
      applyDetail({ sectionId: this.sectionId }).then(res => {
        if (res.data.errorMsg === '用户未登录') {
          this.visible = true;
        } else if (res.data.errorMsg === '系统异常') {
          this.$message.warning(res.data.errorMsg);
        } else {
          const data = Object.assign([], res.data.data);
          if (data.certification) {
            Object.keys(data.certification).forEach(item => {
              Object.keys(this.insureData).forEach(it => {
                if (it === item) {
                  this.insureData[it] = data.certification[item];
                }
              });
            });
          } else {
            this.$router.push({ name: 'entCertForm' });
          }
          Object.keys(data).forEach(item => {
            Object.keys(this.orderData).forEach(it => {
              if (it === item) {
                this.orderData[it] = data[item];
              }
            });
            if (this.orderData.bidOpenTime) {
              this.orderData.bidOpenTime = dateFormat(this.orderData.bidOpenTime);
              this.orderData.payOpenTime = dateFormatDay(this.orderData.bidOpenTime) + ' 00:00:00';
            }
            Object.keys(this.projectData).forEach(it => {
              if (it === item) {
                if (data[item]) {
                  this.projectData[it] = data[item];
                } else {
                  delete this.projectData[it];
                }
                this.projectData = Object.assign({}, this.projectData);
              }
            });
          });
          this.loading = false;
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.project {
  .plate {
    margin-top: 30px;
    .subtitle {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #999999;
      /deep/ .anticon {
        margin-right: 5px;
      }
    }
  }
  .main {
    margin: 50px 0;
    .description {
      margin-bottom: 30px;
    }
  }
  .btn {
    text-align: center;
    padding-top: 40px;
    border-top: 1px solid #EBF2F7;
    /deep/ .ant-btn {
      width: 200px;
      height: 44px;
      border-radius: 4px;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      margin: 0 20px;
      &:nth-child(1) {
        border: 1px solid #007CE2;
        color: #007CE2;
      }
    }
  }
  .palnList {
    display: flex;
    flex-wrap: wrap;
    .palnListBox {
      display: flex;
      align-items: center;
      width: 430px;
      height: 80px;
      margin-bottom: 20px;
      position: relative;
      background: #FFFFFF;
      border: 2px solid #D2E1EC;
      border-radius: 4px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #6A95B5;
      margin-right: 30px;
      cursor: pointer;
      padding-left: 25px;
      transition: all .5s;
      .palnText {
        margin: 20px 0 0 17px;
        span {
          font-size: 16px;
          font-weight: bold;
        }
        p {
          font-size: 14px;
        }
      }
      img {
        width: 40px;
        height: 40px;
      }
      .feilv {
        position: absolute;
        text-align: center;
        top: -2px;
        right: -2px;
        width: 60px;
        height: 20px;
        background: #F83333;
        border-radius: 0px 4px 0px 10px;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #FFFFFF;
      }
    }
    .palnListBoxAct {
      border: 2px solid #36A4FF;
      background: linear-gradient(-30deg, #E6F3FD 0%, #FFFFFF 100%);
      color: #007CE2;
      transition: all .5s;
    }
  }
  .alerter {
    position: fixed;
    top: 50%;
    /deep/ .ant-modal-body {
      display: flex;
      flex-direction: column;
    }
    p {
      text-align: center;
      font-size: 16px;
    }
    /deep/ .anticon svg {
      display: none;
    }
    /deep/ .ant-modal-footer {
      border-top: none;
      padding: 0;
    }
  }
}
</style>
