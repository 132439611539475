<template>
  <div
    class="project"
  >
    <a-alert
      v-if="this.$store.state.info ? this.$store.state.info.certificationFlag === '3' : false"
      :description="'认证审核失败：'+this.$store.state.info.auditMessage+'，请重新提交认证！'"
      type="warning"
      show-ico
      closable
      @close="() => {isAlert = true}"
    />
    <Progress
      size="mini"
      :active-index="activeIndex"
      :step-list="stepList"
    />
    <div v-if="activeIndex === 0">
      <PlateTitle
        title="选择项目"
        class="plate"
      >
        <template
          slot="subtitle"
        >
          <span class="subtitle">
            <a-icon
              type="exclamation-circle"
              theme="outlined"
            />
            当项目、标段数据缺失时，请您联系客服人员</span>
        </template>
      </PlateTitle>
      <div class="search">
        <img
          src="@/assets/image/icon-ss.png"
          alt=""
        >
        <a-input
          v-model="query.keywords"
          placeholder="输入关键词搜索"
        />
        <a-button
          type="primary"
          @click="search"
        >
          查询
        </a-button>
        <a-button
          type="primary"
          @click="reset"
        >
          重置
        </a-button>
      </div>
      <!-- 表格 -->
      <div class="table">
        <a-table
          :columns="columns"
          :pagination="pagination"
          :loading="loading"
          :data-source="data"
          @change="handleTableChange"
        >
          <span
            class="tabProject"
            slot="name"
            slot-scope="space"
          >
            {{ space }}
          </span>
          <span
            slot="time"
            slot-scope="space,text"
          >
            {{ dateFormat(text.showTime) }}
          </span>
          <span
            slot="action"
            slot-scope="space, text"
          >
            <a @click="view(text)">查看</a>
          </span>
        </a-table>
      </div>
    </div>
    <ConInsure v-if="activeIndex === 2" />
  </div>
</template>

<script>
import Progress from '@/components/Progress';
import PlateTitle from '@/components/PlateTitle';
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';
import ConInsure from './components/ConInsure.vue';
import { projects } from '@/api/insure/project.js';
import { dateFormat } from '@/utils/format.js';
import '@/layouts/BasicLayout.vue';
export default {
  name: 'Project',
  components: {
    Progress,
    PlateTitle,
    ConInsure,
  },
  data() {
    return {
      isAlert: false,
      projectAlert: false,
      dateFormat,
      activeIndex: 0, // 进度条
      isView: true,
      query: {},
      locale,
      pagination: {
        total: 0,
      },
      loading: false,
      stepList: [{
        name: '选择项目',
        flag: 0,
      }, {
        name: '确认项目',
        flag: 1,
      }, {
        name: '确认投保',
        flag: 2,
      }, {
        name: '投保审核',
        flag: 3,
      }, {
        name: '保费支付',
        flag: 4,
      }, {
        name: '保函发放',
        flag: 5,
      }],
      data: [],
      columns: [
        {
          title: '项目名称',
          dataIndex: 'name',
          key: 'name',
          slots: { title: '项目' },
          scopedSlots: { customRender: 'name' },
        },
        {
          title: '项目发布时间',
          dataIndex: 'time',
          key: 'time',
          slots: { title: '项目发布时间' },
          scopedSlots: { customRender: 'time' },
        },
        {
          title: '标段数',
          dataIndex: 'sectionCount',
          key: 'sectionCount',
        },
        {
          title: '操作',
          key: 'action',
          scopedSlots: { customRender: 'action' },
        },
      ],
    };
  },
  mounted() {
    this.fetch({ pageSize: 10, pageNum: 1 });
    if (this.$route.query.count) {
      this.activeIndex = Number(this.$route.query.count);
    }
  },
  methods: {
    changeActive(e) {
      this.activeIndex = e;
    },
    search() {
      this.fetch({ keywords: this.query.keywords, pageSize: 10, pageNum: 1 });
    },
    reset() {
      this.query.keywords = null;
      this.search();
    },
    // 页数改变
    handleTableChange(pagination) {
      const pager = { ...this.pagination };
      pager.current = pagination.current;
      this.pagination = pager;
      this.fetch({
        pageSize: pagination.pageSize,
        pageNum: pagination.current,
        keywords: this.query.keywords,
      });
    },
    // 加载
    fetch(params = {}) {
      this.loading = true;
      projects(params).then(res => {
        this.loading = false;
        // eslint-disable-next-line array-callback-return
        // data.data.map((item, index) => {
        //   item.push({ key: index });
        // });
        this.data = res.data.data.content;
        this.pagination = {
          total: res.data.data.totalElements,
        };
      });
    },
    view(e) {
      sessionStorage.setItem('insureProject', JSON.stringify(e));
      this.$router.push({ name: 'ProView', query: { count: 1 } });
    },
  },
  beforeDestroy() {
    if (this.isAlert) {
      this.$store.state.info = {};
    }
  },
};
</script>
<style lang="less" scoped>
.project {
  min-height: 666px;
  /deep/ .ant-alert {
    position: absolute;
    z-index: 99;
    width: 800px;
    color: #E7B24B;
    top: 70px;
    padding: 5px 10px;
  }
  /deep/ .ant-alert-close-icon {
    top: 8px;
  }
  .plate {
    margin-top: 30px;
    .subtitle {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #999999;
      /deep/ .anticon {
        margin-right: 5px;
      }
    }
  }
  .search {
    display: flex;
    position: relative;
    /deep/ .ant-input-affix-wrapper {
      width: 340px;
    }
    /deep/ .anticon {
      margin-top: 10px;
    }
    img {
      width: 14px;
      height: 14px;
      position: absolute;
      top: 40px;
      left: 10px;
      z-index: 99;
    }
    /deep/ .ant-input {
      width: 300px;
      height: 32px;
      background: #FFFFFF;
      border: 1px solid #E1E8EF;
      border-radius: 3px;
      margin: 31px 0 20px 0;
      padding-left: 40px;
    }
    button {
      margin-left: 10px;
      margin-top: 31px;
      background-color: #007CE2;
    }
  }
  .table {
    /deep/ .ant-table-thead > tr > th {
      padding: 19px 30px;
      background-color: #F7FAFF;
      font-size: 15px;
      color: #333;
      font-weight: bold;
    }
    /deep/ .ant-table-thead > tr > th:nth-last-child(1) {
      width: 90px;
    }
    /deep/ .ant-table-thead > tr > th:nth-last-child(2) {
      width: 110px;
    }
    /deep/ .ant-table-tbody > tr > td {
      padding: 19px 30px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      min-width: 45px;
    }
    /deep/ .ant-table-placeholder {
      min-height: 500px;
      .ant-empty-normal {
        margin-top: 220px;
      }
    }
    /deep/ .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
      background-color: #f7faff;
    }
  }
}
</style>
